import React, { useState, useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import './App.css';
import { cn } from '@bem-react/classname';
import {
  DEFAULT_LOCALIZATION,
  WB_INPUT_CHECKBOX_NAMES,
  WB_INPUT_TEXT_NAMES,
  PP_INPUT_CHECKBOX_NAMES
} from './constants';
import { auth, createMessage, exportObjAsJSON } from './helpers';
import { useDropzone } from 'react-dropzone';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Editor from 'react-simple-code-editor';
import Prism from 'prismjs';
import 'prismjs/components/prism-json';

const IframeSettings = ({ updateSettings, settings, classItem }) => {
  const [value, setValue] = useState(settings.src);

  return (
    <div className={classItem("SettingsBlock")}>
      <TextField defaultValue={settings.src} onChange={(e) => setValue(e.target.value)} label="iframe source" />
      <div className={classItem("Btn")}>
        <Button variant="contained" color="primary" onClick={() => updateSettings({ src: value })}>Apply</Button>
      </div>
    </div>
  );
};

IframeSettings.propTypes = {
  // function for update settings
  updateSettings: PropTypes.func,
  // object of iframe settings
  settings: PropTypes.object,
  // classItem for iframe settings
  classItem: PropTypes.func
};

const OpenProject = ({ classItem, refWizard }) => {
  const [number, setNumber] = useState("");

  const _openProject = () => {
    if (refWizard.current) {
      refWizard.current.contentWindow.postMessage({
        "message": "OPEN_PROJECT",
        messageType: "whiteLabelMessage",
        id: number
      }, "*");
    }
  };

  return (
    <div className={classItem("SettingsBlock")}>
      <TextField value={number} onChange={(e) => setNumber(e.target.value)} label="Project for opening" />
      <div className={classItem("Btn")}>
        <Button variant="contained" color="primary" onClick={_openProject}>Open</Button>
      </div>
    </div>
  );
};

OpenProject.propTypes = {
  // ref of wizard iframe
  refWizard: PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  // classItem for iframe settings
  classItem: PropTypes.func
};

const UserSettings = ({ updateSettings, settings, classItem }) => {
  const [values, setValues] = useState(settings);

  const _onChange = (e) => {
    const { name, value } = e.target;
    let newParams = { ...values };
    newParams[name] = value;
    setValues(newParams);
  };

  const _appendAuthData = (userData) => {
    const newParams = { ...values, userData };
    updateSettings(newParams);
  };

  const _login = () => {
    const { userEmail, userPassword } = values;
    auth(userEmail, userPassword, _appendAuthData);
  };

  return (
    <div className={classItem("SettingsBlock")}>
      <div className={classItem("TextField")}>
        <TextField name="userEmail" defaultValue={settings.userEmail} onChange={_onChange} label="User email" />
      </div>
      <div className={classItem("TextField")}>
        <TextField name="userPassword" type="password" defaultValue={settings.userPassword} onChange={_onChange} label="User password" />
      </div>
      <div className={classItem("Btn")}>
        <Button variant="contained" color="primary" onClick={_login}>Login</Button>
      </div>
    </div>
  );
};

UserSettings.propTypes = {
  // function for update settings
  updateSettings: PropTypes.func,
  // object of iframe settings
  settings: PropTypes.object,
  // classItem for iframe settings
  classItem: PropTypes.func
};

const Config = ({ settings, updateSettings }) => {
  const classItem = cn('Config');

  const onDrop = useCallback(acceptedFiles => {
    let newSettings = null;
    const reader = new FileReader();
    // eslint-disable-next-line no-console
    reader.onabort = () => console.log('file reading was aborted');
    // eslint-disable-next-line no-console
    reader.onerror = () => console.log('file reading has failed');
    reader.onload = () => {
      newSettings = JSON.parse(reader.result);
      updateSettings(newSettings);
    };
    reader.readAsText(acceptedFiles[0]);

  }, []);

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  const _onClick = () => {
    exportObjAsJSON(settings);
  };

  return (
    <div className={classItem()}>
      <div {...getRootProps()}>
        <input {...getInputProps()} />
        <div className={classItem("Btn")}>
          <Button variant="contained" color="primary">Load settings</Button>
        </div>
      </div>
      <div className={classItem("Btn", { "Save": true })}>
        <Button variant="contained" color="primary" onClick={_onClick}>Save settings</Button>
      </div>
    </div>
  );
};

Config.propTypes = {
  // object of iframe settings
  settings: PropTypes.object,
  // function for update settings
  updateSettings: PropTypes.func
};

const BaseSettings = ({ updateSettings, settings, refWizard }) => {
  const classItem = cn('BaseSettings');

  const _updateSettings = (newSettings, sectionName) => {

    const updatedSettings = Object.assign({}, settings);
    updatedSettings[sectionName] = Object.assign({}, newSettings);
    updateSettings(updatedSettings);
  };

  const { iframeSettings, userSettings } = settings;

  return (
    <div className={classItem()}>
      <IframeSettings updateSettings={(value) => _updateSettings(value, "iframeSettings")} settings={iframeSettings} classItem={classItem} />
      <UserSettings updateSettings={(value) => _updateSettings(value, "userSettings")} settings={userSettings} classItem={classItem} />
      <OpenProject classItem={classItem} refWizard={refWizard} />
      <RawResponsesInfo classItem={classItem} />
    </div>
  );
};

BaseSettings.propTypes = {
  // function for update settings
  updateSettings: PropTypes.func,
  // object of iframe settings
  settings: PropTypes.object,
  // ref of wizard iframe
  refWizard: PropTypes.shape({ current: PropTypes.instanceOf(Element) })
};

const WhiteLabelSettings = ({ updateSettings, settings }) => {
  const classItem = cn('WhitelabelSettings');

  const _onChange = (e) => {
    const { name } = e.target;
    let value = null;
    if (e.target.type === "checkbox") {
      value = e.target.checked;
    } else {
      value = e.target.value;
    }

    let newSettings = Object.assign({}, settings);
    newSettings[name] = value;
    updateSettings(newSettings);
  };

  const _getInputsByType = (type) => {
    const inputList = (type === "checkbox") ? WB_INPUT_CHECKBOX_NAMES : WB_INPUT_TEXT_NAMES;
    let result = [];

    for (const name in inputList) {
      let inputElement = null;

      if (type === "checkbox") {
        inputElement = <FormControlLabel
          control={
            <Checkbox
              checked={settings[name]}
              onChange={_onChange}
              name={name}
              color="primary" />
          }
          label={inputList[name]} />;
      }
      else {
        inputElement = <TextField type="text" name={name} defaultValue={settings[name]} onChange={_onChange} label={inputList[name]} />;
      }

      result.push(
        <div className={classItem("SettingsBlock")}>
          {inputElement}
        </div>
      );
    }

    return result;
  };

  return (
    <div className={classItem()}>
      {_getInputsByType("checkbox")}
      {_getInputsByType("text")}
    </div>
  );
};

WhiteLabelSettings.propTypes = {
  // object of iframe settings
  settings: PropTypes.object,
  // function for update settings
  updateSettings: PropTypes.func
};

const ProjectPageSettings = ({ updateSettings, settings }) => {
  const classItem = cn('ProjectPageSettings');

  const _onChange = (e) => {
    const { name } = e.target;
    let value = null;
    if (e.target.type === "checkbox") {
      value = e.target.checked;
    } else {
      value = e.target.value;
    }

    let newSettings = Object.assign({}, settings);
    newSettings[name] = value;
    updateSettings(newSettings);
  };

  const _getInputsCheckbox = () => {
    let result = [];

    for (const name in PP_INPUT_CHECKBOX_NAMES) {
      result.push(
        <div className={classItem("SettingsBlock")}>
          <FormControlLabel
            control={
              <Checkbox
                checked={settings[name]}
                onChange={_onChange}
                name={name}
                color="primary" />
            }
            label={PP_INPUT_CHECKBOX_NAMES[name]} />
        </div>
      );
    }

    return result;
  };

  return (
    <div className={classItem()}>
      {_getInputsCheckbox()}
      {/*I think it's not necessary to add one text input in an array */}
      <div className={classItem("SettingsBlock")}>
        <TextField type="text" name="customBackButtonText" defaultValue={settings.customBackButtonText} onChange={_onChange} label="Custom back button text" />
      </div>
    </div>
  );
};

ProjectPageSettings.propTypes = {
  // object of iframe settings
  settings: PropTypes.object,
  // function for update settings
  updateSettings: PropTypes.func
};

const Localization = ({ updateLocalization, localization }) => {
  const classItem = cn('Localization');
  const [value, setValue] = useState(JSON.stringify(localization));


  return (
    <div className={classItem()}>
      {/*<TextField
        id="outlined-multiline-static"
        label="Received messages"
        multiline
        rows={8}
        defaultValue={JSON.stringify(localization)}
        variant="outlined"
      onChange={(e) => setValue(e.target.value)} />*/}
    <Editor
        value={value}
        onValueChange={code => setValue(code)}
        highlight={code => Prism.highlight(code, Prism.languages.json)}
        padding={10}
        style={{
          fontFamily: '"Fira code", "Fira Mono", monospace',
          fontSize: 14,
        }}
      /> 

      <div className={classItem("Btn")}>
        <Button variant="contained" color="primary" onClick={() => updateLocalization(JSON.parse(value))}>Apply</Button>
      </div>
    </div>
  );
};

Localization.propTypes = {
  // function for update localization object
  updateLocalization: PropTypes.func,
  // object, contain localization
  localization: PropTypes.object
};

const Settings = ({ settings, updateSettings, refWizard }) => {
  const classItem = cn('Settings');

  const useExpansionStyles = makeStyles(() => ({
    root: {
      paddingLeft: '0px',
      paddingRight: '0px',
      paddingBottom: '0px'
    }
  }));
  const expansionPanelClass = useExpansionStyles();

  const useExpansionIconStyle = makeStyles(() => ({
    root: {
      border: '1px solid rgba(0, 0, 0, 0.54)',
      borderRadius: '100%'
    }
  }));

  const expansionIconClass = useExpansionIconStyle();

  const _updateSettings = (newSettings, sectionName) => {
    const updatedSettings = Object.assign({}, settings);
    if (typeof (newSettings) === "object") {
      updatedSettings[sectionName] = Object.assign({}, newSettings);
    } else {
      updatedSettings[sectionName] = newSettings;
    }

    updateSettings(updatedSettings);
  };

  const _getWrappedElement = (element, title) => {
    return <ExpansionPanel>
      <ExpansionPanelSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header">
        {title}
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        {element}
      </ExpansionPanelDetails>
    </ExpansionPanel>;
  };

  const { baseSettings, whiteLabelSettings, projectPageSettings, localization } = settings;

  return (
    <div className={classItem()}>
      <ExpansionPanel defaultExpanded={true} >
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon classes={expansionIconClass} />}
          aria-controls="panel1a-content"
          id="panel1a-header">
          Settings
        </ExpansionPanelSummary>
        <ExpansionPanelDetails classes={expansionPanelClass}>
          <div className={classItem("container")}>
            {_getWrappedElement(
              <BaseSettings updateSettings={(value) => _updateSettings(value, "baseSettings")} settings={baseSettings} refWizard={refWizard} />,
              "Base settings")}
            {_getWrappedElement(
              <WhiteLabelSettings updateSettings={(value) => _updateSettings(value, "whiteLabelSettings")} settings={whiteLabelSettings} />,
              "WhiteLabel settings")}
            {_getWrappedElement(
              <ProjectPageSettings updateSettings={(value) => _updateSettings(value, "projectPageSettings")} settings={projectPageSettings} />,
              "Project page settings")}
            {_getWrappedElement(
              <Localization updateLocalization={(value) => _updateSettings(value, "localization")} localization={localization} />,
              "Localization")}
            {_getWrappedElement(
              <Config settings={settings} updateSettings={updateSettings} />,
              "Config")}
            {_getWrappedElement(
              <OutputMessages params={settings} refWizard={refWizard} />,
              "Output messages")}
          </div>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </div>
  );
};

Settings.propTypes = {
  // object of iframe settings
  settings: PropTypes.object,
  // function for update settings
  updateSettings: PropTypes.func,
  // ref of wizard iframe
  refWizard: PropTypes.shape({ current: PropTypes.instanceOf(Element) })
};

const RawResponsesInfo = ({ classItem }) => {
  const [outputData, setOutputData] = useState("");

  const receiveMessage = (e) => {
    let data = "";
    try {
      data = typeof e.data === "string" ? JSON.parse(e.data) : e.data;
    } catch (ex) {
      //console.error("receiveMessage ex", ex, e.data);
    }

    let checkMessage = (data &&
      (
        data.message === "EXPORT_DONE_MESSAGE" ||
        data.message === "LOGIN_DONE_MESSAGE" ||
        data.message === "BACK_MESSAGE" ||
        data.message === "EDITOR_READY" ||
        data.message === "OPEN_PROJECT" ||
        data.message === "OPEN_PROJECT_DONE_MESSAGE"
      )
      && data._ym_messenger !== true && data.type !== "MBR_STORAGE" && data.type !== "MBR_ENVIRONMENT");

    if (checkMessage) {
      setOutputData(JSON.stringify(data) + "\n\n" + outputData);
    }
  };

  useEffect(() => {
    window.addEventListener("message", receiveMessage, false);

    return () => {
      window.removeEventListener("message", receiveMessage, false);
    };
  }, [outputData]);

  const _clearTextArea = () => {
    setOutputData("");
  };

  return (
    <div className={classItem("SettingsBlock")}>
      <TextField
        id="outlined-multiline-static"
        label="Received messages"
        multiline
        rows={5}
        value={outputData}
        variant="outlined" />
      <div className={classItem("Btn")}>
        <Button variant="contained" color="primary" onClick={_clearTextArea}>Clear</Button>
      </div>
    </div>
  );
};

RawResponsesInfo.propTypes = {
  // classItem for RawResponsesInfo
  classItem: PropTypes.func,
};

const OutputMessages = ({ params }) => {
  const classItem = cn('OutputMessages');

  const message = createMessage(params);

  return (
    <div className={classItem()}>
      <TextField
        id="outlined-multiline-static"
        label="Output message"
        multiline
        rows={8}
        value={JSON.stringify(message)}
        variant="outlined" />
    </div>
  );
};

OutputMessages.propTypes = {
  // object of params, which will be sended
  params: PropTypes.object,
  // ref of wizard iframe
  refWizard: PropTypes.shape({ current: PropTypes.instanceOf(Element) })
};

function App() {
  const classItem = cn('main');
  const refWizard = useRef(null);
  const [settings, setSettings] = useState({
    baseSettings: {
      iframeSettings: {
        src: "https://wizard.viewst-st.com"
      },
      userSettings: {
        userEmail: "",
        userPassword: "",
        numberProjectOpen: "",
        userData: null
      }
    },
    whiteLabelSettings: {
      isSwitched: false,
      showOnlyDownloadBtn: false,
      showOnlyArchiveLink: false,
      hideFormats: false,
      showWizardLogo: false,
      showUserProfile: false,
      isResizeImages: false,
      isBase64: false,
      showSupportChat: false,
      saveArchiveOnCloud: false,
      videoUrl: "",
      videoWidth: "",
      videoHeight: "",
      videoClickURL: "",
      partnerStyles: "default",
      editorLang: "ru"
    },
    projectPageSettings: {
      showUploadButton: false,
      showCreateNewButton: false,
      showCustomBackButton: false,
      customBackButtonText: "Exit"
    },

    localization: DEFAULT_LOCALIZATION
  });

  const [autoSendMode, setAutoSendMode] = useState(false);

  const _sendMessage = () => {
    const message = createMessage(settings);
    
    if (refWizard.current) {
      refWizard.current.contentWindow.postMessage(message, "*");
    }
  };

  if (autoSendMode) {
    _sendMessage();
  };

  return (
    <div className={classItem()}>
      <a id="downloadAnchorElem" />
      <Settings updateSettings={setSettings} settings={settings} refWizard={refWizard} />
      <div className={classItem("Panel")}>
        <Button variant="contained" color="primary" onClick={_sendMessage}>Send to wizard</Button>
        <FormControlLabel
            control={
              <Checkbox
                checked={autoSendMode}
                onChange={() => {setAutoSendMode(!autoSendMode);}}
                color="primary" />
            }
            label="Auto send mode" />
      </div>
      <iframe ref={refWizard} title="Wizard Viewst" src={settings.baseSettings.iframeSettings.src} className={classItem("wizard")} id="wizard"></iframe>
    </div>
  );
}

export default App;
