export const auth = (email, password, callback) => {
  fetch(`https://api.viewst-st.com/passport/v1/users/login-or-register`, {
          method: 'post',
          headers: {
              'Content-type': 'application/json'
          },
          body: JSON.stringify({
            password,
            email
          })
      }).then(response => {
          if (response.ok) {
              return response.json().then((json) => {
                  // eslint-disable-next-line no-console
                  console.log("auth json", json);
                  callback(json);
              });
          } else {
              return response.json().then(response => {
                  // eslint-disable-next-line no-console
                  console.log(`${response.message}`);
              });
          }
      }).catch(error => {
          // eslint-disable-next-line no-console
          console.error('error: ', error);
      });
};

export const createMessage = (settings) => {

    const { baseSettings, whiteLabelSettings, projectPageSettings, localization } = settings;

    let message = {
        messageType: "whiteLabelMessage",
        isWhiteLabelMode: whiteLabelSettings.isSwitched,
        whiteLabelPartner: whiteLabelSettings.partnerStyles,  
        showLogo: whiteLabelSettings.showWizardLogo, 
        showUserProfile: whiteLabelSettings.showUserProfile,
        showSupportChat: whiteLabelSettings.showSupportChat,
        projectSettings: {
            showUploadProjectButton: projectPageSettings.showUploadButton,
            showSmallNewProjectButton: projectPageSettings.showCreateNewButton,
            showCustomBackButton: projectPageSettings.showCustomBackButton,
            customBackButtonText: projectPageSettings.customBackButtonText
        },
        exportSettings: {
            showOnlyDownloadButton: whiteLabelSettings.showOnlyDownloadBtn,
            showSaveArchiveLink: whiteLabelSettings.showOnlyArchiveLink,
            isBase64: whiteLabelSettings.isBase64,
            isResizeImages: whiteLabelSettings.isResizeImages,
            saveOnCloudOnDownload: whiteLabelSettings.saveArchiveOnCloud
        },
        formatsSettings: {
            hideGroupIds: whiteLabelSettings.hideFormats ? [1, 2, 3] :[]
        },
        creativeSettings: {
            formatParams: {
                videoParams: {
                    videoUrl: whiteLabelSettings.videoUrl,
                    videoWidth: whiteLabelSettings.videoWidth,
                    videoHeight: whiteLabelSettings.videoHeight,
                    videoClickUrl: whiteLabelSettings.videoClickURL
                }
            }
        },
        userSettings: {
            userLanguage: whiteLabelSettings.editorLang
        },
        localization: localization
    };

    if (baseSettings.userSettings.userData)
    {
        const { userData } = baseSettings.userSettings;
        message = {...message, userData};
    }

    return message;
};

export const exportObjAsJSON = (obj) => {
    let dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(obj));
    let dlAnchorElem = document.getElementById('downloadAnchorElem');
    dlAnchorElem.setAttribute("href", dataStr);
    dlAnchorElem.setAttribute("download", "config.json");
    dlAnchorElem.click();
};