export const DEFAULT_LOCALIZATION = {
  "header": {
      "format": "Формат",
      "design": "Дизайн",
      "animation": "Анимация",
      "settings": "Настройки",
      "download": "Завершить работу",
      "save": "Сохранить изменения",
      "back_to_project": "Выход",
      "projects": "Проекты",
      "changes_saved": "Изменения сохранены {{minutes}} мин. назад"
  } /*,
  "projectsPage": {
      "my_projects": "Мои проекты",
      "new_project": "Новый проект",
      "upload_project": "Загрузить проект",
      "sort_by_date": "По дате",
      "sort_by_name": "По имени",
      "duplicate": "копировать",
      "delete": "удалить",
      "rename": "переименовать",
      "choose_template": "Выберите шаблон",
      "select_all": "выбрать все",
      "unselect_all": "очистить выбор",
      "sort_newest": "Сначала новые",
      "sort_older": "Сначала старые",
      "sort_alphabetically": "По алфавиту"
  },
  "ctaData": {
      "buy_now": "КУПИТЬ СЕЙЧАС",
      "find_out_more": "УЗНАТЬ БОЛЬШЕ",
      "play": "ИГРАТЬ!",
      "learn_more": "УЗНАТЬ БОЛЬШЕ",
      "like": "Нравится",
      "take_away": "Забрать",
      "log_in": "“ВОЙТИ”",
      "try_this": "“ПОПРОБОВАТЬ”",
      "order_call": "Заказать звонок"
  },
  "textData": {
      "title": "Заголовок",
      "regular_text": "Обычный текст",
      "black_friday": "ЧЕРНАЯ ПЯТНИЦА",
      "50_sale": "Скидки 50% на все!",
      "dont_miss": "Не пропусти!",
      "coming_november": "Смотрите в ноябре",
      "win_trip_for_two": "Выиграйте путешествие!",
      "refuel_yourself": "Заправься по полной!",
      "new_taste": "Новый вкус",
      "new_level": "НА НОВОЙ ВЫСОТЕ",
      "20_to_50": "От 20 до 50%",
      "believe_magic": "Поверь в волшебство!",
      "live_full_live": "Для полноценной жизни",
      "new_collection": "Новая коллекция",
      "fall_winter_2020": "Осень-зима 2020",
      "for_your_baby": "Для вашего малыша",
      "new_season": "Новый сезон",
      "deposit_online": "Онлайн-депозит"
  },
  "exportPage": {
      "export": "Экспорт",
      "creative_link": "Ссылка на креатив",
      "archive_link": "Ссылка на архив",
      "vast_link": "VAST ссылка",
      "save_settings": "Настройки сохранения",
      "banner_image_link": "Ссылка на изображение баннера",
      "additional_options": "Дополнительные ссылки...",
      "generate_video": "Создать видео",
      "video_ad": "Video ссылка",
      "gif_ad": "GIF ссылка",
      "download": "Перейти к настройкам Флайта",
      "open": "Открыть ссылку",
      "download_html_archive": "HTML5-архив",
      "png": "PNG",
      "jpg": "JPG",
      "vast": "VAST",
      "floating": "Плавающий баннер",
      "mp4_video": "MP4 видео",
      "animation_header": "Анимации",
      "animated_gif": "GIF",
      "download_viewst_file": "Создать",
      "backup_header": "Резервная копия",
      "download_as_header": "Сохранить",
      "save_in_the_cloud": "Сохранить в облаке",
      "static_image_header": "Статичное изображение",
      "code_header": "Код",
      "save_project_header": "Сохранить файл проекта",
      "save_on_viewst_button": "Сохранить на Viewst",
      "save_on_viewst_unavailable": "Сохранение в облаке Viewst временно недоступно. Данная функция будет доступна для всех пользователей в ближайшее время.",
      "save_images_as_base64": "Сохранить изображение в формате base64",
      "base64_option_description": "Позволяет вам сохранить все ресурсы внутри одного index.html файла, но существенно увеличивает его вес.",
      "resize_saved_images": "Ресайз сохраненных изображений"
  }*/
};

export const WB_INPUT_CHECKBOX_NAMES = {
  isSwitched: "Switch on/off whitelabel mode",
  showOnlyDownloadBtn: "Show only download button on Export",
  showOnlyArchiveLink: "Show archive link for download",
  hideFormats: "Show only VAST formats",
  showWizardLogo: "Show wizard logo",
  showUserProfile: "Show user profile",
  isResizeImages: "Resize Images",
  isBase64: "Using base 64",
  showSupportChat: "Show support chat",
  saveArchiveOnCloud: "Save on cloud when user download archive"
};

export const WB_INPUT_TEXT_NAMES = {
  videoUrl: "Video URL",
  videoWidth: "Video Width",
  videoHeight: "Video Height",
  videoClickURL: "Video Click URL",
  partnerStyles: "Partner styles",
  editorLang: "Editor language"
};

export const PP_INPUT_CHECKBOX_NAMES = {
  showUploadButton: "Show upload button",
  showCreateNewButton: "Show create new button",
  showCustomBackButton: "Show custom back button"
};